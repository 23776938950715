<template>
  <div id="base">
    <nav-bar />
    <router-view></router-view>
    <footer-component />
    <float-whatsapp />
  </div>
</template>

<style>
@import '~@fontsource/open-sans/300.css';
@import '~@fontsource/open-sans/400.css';
@import '~@fontsource/open-sans/600.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~@a/css/bootstrap.css';

@import '~@a/css/templatemo.css';
@import '../../node_modules/boxicons/css/boxicons.min.css';
</style>

<script>
import NavBar from '@/components/BaseLayout/NavBar';
import FooterComponent from '@/components/BaseLayout/Footer';
import 'bootstrap';
import 'boxicons';
import FloatWhatsapp from '@/components/BaseLayout/FloatWhatsapp';
import '@fortawesome/fontawesome-free/js/all.js';
//https://fontawesome.com/v5.15/how-to-use/on-the-web/other-topics/performance
//https://fontawesome.com/v5.15/icons?d=gallery&p=2&m=free

// todo: inteaoria only footer and navbar
export default {
  name: 'Base',
  components: {
    FloatWhatsapp,
    NavBar,
    FooterComponent,
  },
};
</script>
