<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  watch: {
    // $route(to, from) {
    $route(to) {
      document.title = to.meta.title || 'Your Website';
    },
  },
};
</script>

<style lang="scss">
$primaryBackground: #0477bf;
$primaryColor: #eee;

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

/*#nav {*/
/*  padding: 30px;*/
/*}*/

/*#nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

#main_nav a.router-link-exact-active {
  color: $primaryColor !important;
  background: $primaryBackground;
}

.text-justify {
  text-align: justify;
}
</style>
