<template>
  <nav id="main_nav" class="navbar navbar-expand-lg navbar-light bg-white shadow">
    <div class="container d-flex justify-content-between align-items-center">
      <a class="navbar-brand h1" href="/">
        <img :src="require('@a/img/hidra/isotipo.svg')" width="36px" height="36px" class="pe-2" />
        <span class="text-primary h4">Hidra</span>
        <span class="text-dark h4"> brand consulting</span>
      </a>
      <button
        class="navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-toggler-success"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="align-self-center collapse navbar-collapse flex-fill d-lg-flex justify-content-lg-between"
        id="navbar-toggler-success"
      >
        <div class="flex-fill mx-xl-5 mb-2">
          <ul class="nav navbar-nav d-flex justify-content-between mx-xl-5 text-center text-dark">
            <li class="nav-item" v-for="(route, index) in routes" :key="index">
              <router-link class="nav-link btn-outline-primary rounded-pill px-3" :to="{ name: route.link }"
                >{{ route.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      routes: [
        {
          name: 'Inicio',
          link: 'Home',
        },
        {
          name: 'Servicios',
          link: 'Services',
        },
        {
          name: 'Acerca de',
          link: 'About',
        },
        {
          name: 'Registra tu marca',
          link: 'Register trademark',
        },
        {
          name: 'Contacto',
          link: 'Contact',
        },
      ],
    };
  },
};
</script>
