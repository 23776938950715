<template>
  <div :class="$style.component">
    <!--    <a href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202." class="float-anchor" target="_blank">-->
    <a
      :href="'https://api.whatsapp.com/send?phone=' + this.$phone + '&text=Hola quisiera más información.'"
      class="float-anchor"
      target="_blank"
    >
      <i class="fab fa-whatsapp my-float"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FloatWhatsapp',
  data() {
    return {};
  },
};
</script>

<style lang="scss" module>
.component :global {
  .float-anchor {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .my-float {
    margin-top: 16px;
  }
}
</style>
