<template>
  <footer class="bg-secondary">
    <div class="container">
      <div class="row py-4">
        <div class="col-lg-4 col-md-6 col-12">
          <router-link class="navbar-brand" :to="{ name: 'Home' }">
            <div class="card bg-white">
              <div class="card-body">
                <img :src="require('@a/img/hidra/isotipo.svg')" width="36px" height="36px" class="pe-2" />
                <span class="text-primary h5 semi-bold-600">Hidra</span>
                <span class="text-dark h5"> brand consulting</span>
                <div class="text-muted h6">Especialistas en registro de marca</div>
              </div>
            </div>
          </router-link>
          <!--          <ul class="list-inline footer-icons light-300">-->
          <!--            <li class="list-inline-item m-0" v-for="(socialLink, index) in socialLinks" :key="index">-->
          <!--              <a class="text-light" target="_blank" :href="socialLink.href">-->
          <!--                <i :class="[socialLink.icon, 'bx', 'bx-md']"></i>-->
          <!--              </a>-->
          <!--            </li>-->
          <!--          </ul>-->
        </div>
        <div class="col-lg-4 d-md-none d-lg-block col-12"></div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card">
            <div class="card-body">
              <span style="font-size: 24px" class="text-primary">
                <i class="fa fa-phone"></i>
              </span>
              <span class="text-dark h5"> Contacto </span>
              <div class="text-muted h6">{{ formattedPhone }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 bg-primary py-3">
      <div class="container">
        <div class="row pt-2">
          <div class="col-lg-6 col-sm-12">
            <p class="text-lg-start text-center text-light light-300">
              © Copyright 2021 Hidra brand consulting. Todos los derechos reservados.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      socialLinks: [
        // {
        //   href: 'http://facebook.com/',
        //   icon: 'bxl-facebook-square',
        // },
        // {
        //   href: 'https://www.linkedin.com/',
        //   icon: 'bxl-linkedin-square',
        // },
        // {
        //   href: 'https://www.whatsapp.com/',
        //   icon: 'bxl-whatsapp-square',
        // },
        // {
        //   href: 'https://www.flickr.com/',
        //   icon: 'bxl-flickr-square',
        // },
        // {
        //   href: 'https://www.medium.com/',
        //   icon: 'bxl-medium-square',
        // },
      ],
    };
  },
  computed: {
    formattedPhone() {
      var phone = this.$phone;

      var match = phone.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
      return match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4];
    },
  },
};
</script>
