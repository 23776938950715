import Vue from 'vue';
import VueRouter from 'vue-router';
import Base from '@/views/Base';
import Clean from '@/views/Clean';

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: '/acerca_de',
    name: 'About',
    component: () => import('@/views/About'),
    meta: {
      title: 'Acerca de',
    },
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import('@/views/Contact'),
    meta: {
      title: 'Contacto',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      title: 'Inicio',
    },
  },
  {
    path: '/servicios',
    name: 'Services',
    component: () => import('@/views/Services'),
    meta: {
      title: 'Servicios',
    },
  },
  {
    path: '/registrar_marca',
    name: 'Register trademark',
    component: () => import('@/views/RegisterTrademark'),
    meta: {
      title: 'Registrar marca',
    },
  },
];

const cleanRoutes = [
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/Error-404'),
  },
];

const routes = [
  {
    path: '/',
    name: 'BaseLayout',
    component: Base,
    children: baseRoutes,
  },
  {
    path: '/',
    name: 'CleanLayout',
    component: Clean,
    children: cleanRoutes,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
